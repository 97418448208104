import "./App.css";
import { BgcFormV2 } from "./Components/BgcFormV2";

function App() {
  return (
    <div className="App">
      <BgcFormV2 />
    </div>
  );
}

export default App;
